import React from 'react';
import SmashitButton from './SmashitButton';
import './App.css';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      buttons: []
    }
  }

  scan() {

    const id2color = {
      'eWcPn0hYvhzWzNcJJLGeaA==': 'green',
      '5097IaqKeWhvQgSRrwk0Xg==': 'blue',
      'yyulhOcHgUzzM9ho30EIsw==': 'purple'
    };

    const filters = [{
      services: [0x2220]
    }];

    // const filters = [{
    //   name: "SMASHY"
    // }];

    var device = null;

    navigator.bluetooth.requestDevice({ filters: filters })
    .then(_device => {
      device = _device;
      console.log(device);
      // device.addEventListener('advertisementreceived', event => {
      //   console.log('onadvertisementreceived', event);
      // })
      // return device.watchAdvertisements();
      return device.gatt.connect();
    })
    .then(server => {
      console.log(server);
      return server.getPrimaryService(0x2220);
    })
    .then(service => {
      return service.getCharacteristic(0x2221);
    })
    .then(characteristic => characteristic.startNotifications())
    .then(characteristic => {
      // Set up event listener for when characteristic value changes.
      characteristic.addEventListener('characteristicvaluechanged', (event) => {
        const incomingType = event.target.value.getUint8(0);
        switch(incomingType) {
          case 0:
            var buttonState = event.target.value.getUint8(1);
            var buttons = this.state.buttons;
            var button = buttons.find(button => button.id === device.id);
            if(!button) {
              var color = id2color[device.id];
              if(!color) color = device.id;
              button = {id: device.id, color: color, pressed: null};
              buttons.push(button);
            }
            button.pressed = (buttonState === 1);
            this.setState({buttons: buttons});
            console.log('Button state changed to ' + buttonState);
            break;

          case 1:
            var rssi = event.target.value.getUint8(1);
            console.log('rssi changed to ' + rssi);
            break;

          case 4:
            var sensorValue = event.target.value.getUint8(1) * 1024.0 / 255.0;
            var batteryVoltage = sensorValue * 3.6 / 1023.0;
            console.log('batteryVoltage changed to ' + batteryVoltage);
            break;

          default:
            break;
        }
      });

      return characteristic.readValue();
    })
    .then(value => {
      // console.log(`Button state: ${value.getUint8(0)}`);
    })
    .catch(error => { console.error(error); });
  }

  render() {
    const renderedButtons = this.state.buttons.map(button => {
      return (<SmashitButton key={button.id} color={button.color} pressed={button.pressed} />)
    });

    return (
      <div className="App">
        <button onClick={() => {this.scan()}}>Scan</button>
        <div>{renderedButtons}</div>
      </div>
    );
  }
}

export default App;

