import React from 'react';
import blueButton from './blue-new.png';
import blueButtonPressed from './blue-new-pressed.png';
import orangeButton from './orange-new.png';
import orangeButtonPressed from './orange-new-pressed.png';
import purpleButton from './purple-new.png';
import purpleButtonPressed from './purple-new-pressed.png';
import greenButton from './green-new.png';
import greenButtonPressed from './green-new-pressed.png';


class SmashitButton extends React.Component {

  render() {
    switch(this.props.color) {
      case 'blue':
      return (<img alt='green' src={this.props.pressed?blueButtonPressed:blueButton} />)

      case 'orange':
      return (<img alt='green' src={this.props.pressed?orangeButtonPressed:orangeButton} />)

      case 'purple':
      return (<img alt='green' src={this.props.pressed?purpleButtonPressed:purpleButton} />)

      case 'green':
      return (<img alt='green' src={this.props.pressed?greenButtonPressed:greenButton} />)

      default:
      return (<img alt='green' src={this.props.pressed?greenButtonPressed:greenButton} />)
    }

  }
}

export default SmashitButton;
